import Breadth from "@/components/Layout/Breadth";
import LayoutWrapper from "@/components/Layout/LayoutWrapper";
import "@/styles/globals.css";
import "@/styles/tailwind.css";
import "@/styles/carousel.scss";
import "@/styles/playbutton.css";
import "@smastrom/react-rating/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-google-reviews/dist/index.css";
import { NextUIProvider } from "@nextui-org/react";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import store from "@/store";
import { ToastContainer } from "react-toastify";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { SpeedInsights } from "@vercel/speed-insights/next";
import GoogleAnalytics from "@/components/Shares/GoogleAnalytics";
import { Analytics } from "@vercel/analytics/react";
import { GoogleTagManager } from "@next/third-parties/google";
import { useEffect } from "react";

const initialOptions = {
  clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
  currency: "AUD",
};

const gtmId = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string;

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WNH9H44C";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  }, []);

  return (
    <PayPalScriptProvider options={initialOptions}>
      <SpeedInsights />
      <Analytics />
      <Provider store={store}>
        <GoogleAnalytics />
        <NextUIProvider>
          <ToastContainer />
          <Breadth />
          <LayoutWrapper>
            <Component {...pageProps} />
            <GoogleTagManager gtmId={gtmId} />
          </LayoutWrapper>
        </NextUIProvider>
      </Provider>
    </PayPalScriptProvider>
  );
}
